import React from "react";
import nodata from "../images/nodata.jpg";

function Error404() {
  return (
    <div className="flex flex-col justify-center items-center h-[calc(100dvh-78px)]">
      <img src={nodata} alt="nodata" className="w-48" />
      <p className="font-medium text-gray-400 text-center">No Data Found</p>
    </div>
  );
}

export default Error404;
