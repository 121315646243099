import React from "react";
import ScrollActive from "../../components/ScrollActive";
import Topbar from "../../components/Topbar";
import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import PreviousBtn from "../../components/PreviousBtn";
import NextBtn from "../../components/NextBtn";
import RightBar from "../../components/RightBar";
import wsa1 from "../../images/omnichannelimg/wsa1.jpg";
import wsa5 from "../../images/omnichannelimg/wsa5.jpg";
import navigate_Messenger from "../../images/omnichannelimg/messengerintegrationimg/navigate_Messenger.jpg";
import click_Connect from "../../images/omnichannelimg/messengerintegrationimg/click_Connect.jpg";
import sign_Facebook from "../../images/omnichannelimg/messengerintegrationimg/sign_Facebook.jpg";
import choose_continue from "../../images/omnichannelimg/messengerintegrationimg/choose_continue.jpg";
import choose_Pages_Continue from "../../images/omnichannelimg/messengerintegrationimg/choose_Pages_Continue.jpg";
import review_permission from "../../images/omnichannelimg/messengerintegrationimg/review_permission.jpg";
import connected_xunified from "../../images/omnichannelimg/messengerintegrationimg/connected_xunified.jpg";
import fb_Business_Click_Save from "../../images/omnichannelimg/messengerintegrationimg/fb_Business_Click_Save.jpg";
import complete_Setup from "../../images/omnichannelimg/messengerintegrationimg/complete_Setup.jpg";
import fb_channel_connected_successfully from "../../images/omnichannelimg/messengerintegrationimg/fb_channel_connected_successfully.jpg";
import navigate_application from "../../images/omnichannelimg/whatsAppintegrationimg/navigate_application.jpg";
import click_existing_application from "../../images/omnichannelimg/whatsAppintegrationimg/click_existing_application.jpg";
import select_Bot from "../../images/omnichannelimg/whatsAppintegrationimg/select_Bot.jpg";
import connect_Bot from "../../images/omnichannelimg/whatsAppintegrationimg/connect_Bot.jpg";
import connect_facebook from "../../images/omnichannelimg/messengerintegrationimg/connect_facebook.jpg";
import navigate_live_chat from "../../images/omnichannelimg/messengerintegrationimg/navigate_live_chat.jpg";

const rightBarMenu = [
  {
    tolink: "step-1-navigate-to-omni-channel",
    submenutext: "Step - 1 Navigate to Omni Channel",
  },
  {
    tolink: "step-2-navigate-to-messenger",
    submenutext: "Step - 2 Navigate to Messenger",
  },
  {
    tolink: "step-3-click-connect",
    submenutext: "Step - 3 Click Connect",
  },
  {
    tolink: "step-4-click-sign-in-with-facebook",
    submenutext: "Step - 4 Click Sign in with Facebook",
  },
  {
    tolink: "step-5-login-to-facebook",
    submenutext: "Step - 5 Login to Facebook",
  },
  {
    tolink: "step-6-choose-the-business-access-and-click-continue",
    submenutext: "Step - 6 Choose the Business access and Click Continue",
  },
  {
    tolink: "step-7-choose-the-pages-access-and-click-continue",
    submenutext: "Step - 7 Choose the Page access and Click Continue",
  },
  {
    tolink: "step-8-review-and-click-save",
    submenutext: "Step - 8 Review and Click Save",
  },
  {
    tolink: "step-9-connected-to-xunifiedai-and-click-got-it",
    submenutext: "Step - 9 Connected to xunified and Click Got it",
  },
  {
    tolink: "step-10-facebook-business-page-and-click-save",
    submenutext: "Step - 10 Facebook Business Page and Click Save",
  },
  {
    tolink: "step-11-click-complete-setup",
    submenutext: "Step - 11 Click Complete Setup",
  },
  {
    tolink: "step-12-navigate-to-application",
    submenutext: "Step - 12 Navigate to Application",
  },
  {
    tolink: "step-13-click-on-existing-application",
    submenutext: "Step - 13 Click on Existing Application",
  },
  {
    tolink: "step-14-select-bot",
    submenutext: "Step - 14 Select Bot",
  },
  {
    tolink: "step-15-connect-bot",
    submenutext: "Step - 15 Connect Bot",
  },
  {
    tolink: "step-16-connect-facebook-page",
    submenutext: "Step - 16 Connect Facebook Page",
  },
  {
    tolink: "step-17-send-fb-message-from-configured",
    submenutext:
      "Step - 17 Send Facebook message from configured Facebook page",
  },
  {
    tolink: "step-18-navigate-to-live-chat",
    submenutext: "Step - 18 Navigate to Live Chat",
  },
  {
    tolink: "step-19-open-the-chat",
    submenutext: "Step - 19 Open the Chat",
  },
];

function MessengerIntegration() {
  return (
    <>
      <ScrollActive rightBarMenu={rightBarMenu} />
      <Topbar rightBarMenu={rightBarMenu} />

      <div className="lg:flex gap-6 sm:p-8 p-5 text-gray-600">
        <div className="flex-1">
          <Title title="Messenger Integration" />

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 1 Navigate to Omni Channel"
              hrefLink="step-1-navigate-to-omni-channel"
            />
            <p className="mb-2">
              Begin by accessing the Omni Channel feature within your platform
              XUnified.
            </p>
            <img src={wsa1} alt="wsa1" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 2 Navigate to Messenger"
              hrefLink="step-2-navigate-to-messenger"
            />
            <p className="mb-2">
              Locate and select the Messenger option within the Omni Channel.
            </p>
            <img
              src={navigate_Messenger}
              alt="navigate_Messenger"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 3 Click Connect"
              hrefLink="step-3-click-connect"
            />
            <p className="mb-2">
              Initiate the connection process by clicking on the "Connect"
              button.
            </p>
            <img src={click_Connect} alt="click_Connect" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 4 Click Sign in with Facebook, in Connect Facebook Page pop-up"
              hrefLink="step-4-click-sign-in-with-facebook"
            />
            <p className="mb-2">
              Authorize access to your Facebook account by clicking on "Sign in
              with Facebook" within the Connect Facebook Page pop-up window.
            </p>
            <img src={sign_Facebook} alt="sign_Facebook" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 5 Login to Facebook"
              hrefLink="step-5-login-to-facebook"
            />
            <p className="mb-2">
              Enter your Facebook credentials to log in and grant access to the
              required permissions.
            </p>
            <img src={wsa5} alt="wsa5" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 6 Choose the Business you want xunified to access and Click Continue"
              hrefLink="step-6-choose-the-business-access-and-click-continue"
            />
            <p className="mb-2">
              Select the specific Facebook Pages that you wish to integrate with
              xunified, then proceed by clicking on "Continue."
            </p>
            <img
              src={choose_continue}
              alt="choose_continue"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 7 Choose the Pages you want xunified to access and Click Continue"
              hrefLink="step-7-choose-the-pages-access-and-click-continue"
            />
            <p className="mb-2">
              Select the specific Facebook Pages that you wish to integrate with
              xunified, then proceed by clicking on "Continue."
            </p>
            <img
              src={choose_Pages_Continue}
              alt="choose_Pages_Continue"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 8 Review what xunified is requesting permission to do and Click Save"
              hrefLink="step-8-review-and-click-save"
            />
            <p className="mb-2">
              Carefully review the permissions requested by xunified for your
              selected Facebook Pages, then confirm your selection by clicking
              on "Save."
            </p>
            <img
              src={review_permission}
              alt="review_permission"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 9 Connected to xunified and Click Got it"
              hrefLink="step-9-connected-to-xunifiedai-and-click-got-it"
            />
            <p className="mb-2">
              Acknowledge the successful connection to xunified by clicking on
              "Got it."
            </p>
            <img
              src={connected_xunified}
              alt="connected_xunified"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 10 Select your Facebook Business Page and Click Save"
              hrefLink="step-10-facebook-business-page-and-click-save"
            />
            <p className="mb-2">
              Choose the designated Facebook Business Page you want to associate
              with xunified, then save your selection by clicking on "Save."
            </p>
            <img
              src={fb_Business_Click_Save}
              alt="fb_Business_Click_Save"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 11 Click Complete Setup"
              hrefLink="step-11-click-complete-setup"
            />
            <p className="mb-2">
              Finalize the setup process by clicking on "Complete Setup,"
              confirming that the Facebook channel has been successfully
              connected.
            </p>
            <img src={complete_Setup} alt="complete_Setup" className="border" />
          </div>

          <div className="mb-8">
            <p className="mb-2">Messenger connected successfully</p>
            <img
              src={fb_channel_connected_successfully}
              alt="fb_channel_connected_successfully"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 12 Navigate to Application"
              hrefLink="step-12-navigate-to-application"
            />
            <p className="mb-2">
              Proceed to the Application section within your platform XUnified.
            </p>
            <img
              src={navigate_application}
              alt="navigate_application"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 13 Click on Existing Application, it will move to application detail"
              hrefLink="step-13-click-on-existing-application"
            />
            <p className="mb-2">
              Click an existing application, which will redirect you to the
              application details page.
            </p>
            <img
              src={click_existing_application}
              alt="click_existing_application"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 14 Select Bot"
              hrefLink="step-14-select-bot"
            />
            <p className="mb-2">
              In the application details, select the Bot option from the Bot
              Connect drop-down.
            </p>
            <img src={select_Bot} alt="select_Bot" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 15 Connect Bot"
              hrefLink="step-15-connect-bot"
            />
            <p className="mb-2">
              Initiate the connection process for your bot with an application
              by selecting the appropriate bot.
            </p>
            <img src={connect_Bot} alt="connect_Bot" className="border" />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 16 Connect Facebook Page"
              hrefLink="step-16-connect-facebook-page"
            />
            <p className="mb-2">
              Connect your Facebook Page to an application by clicking on the
              "Connect" button.
            </p>
            <img
              src={connect_facebook}
              alt="connect_facebook"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 17 Send Facebook message from configured Facebook page"
              hrefLink="step-17-send-fb-message-from-configured"
            />
            <p className="mb-2">
              Test the integration by sending a message from your configured
              Facebook Page to verify functionality.
            </p>
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 18 Navigate to Live Chat"
              hrefLink="step-18-navigate-to-live-chat"
            />
            <p className="mb-2">
              Navigate to the Live Chat section within your platform XUnified.
            </p>
            <img
              src={navigate_live_chat}
              alt="navigate_live_chat"
              className="border"
            />
          </div>

          <div className="mb-8">
            <SubTitle
              subtitle="Step - 19 Open the Chat"
              hrefLink="step-19-open-the-chat"
            />
            <p className="mb-2">
              Open a live chat to begin engaging with users through the
              integrated Facebook Messenger channel.
            </p>
          </div>

          <div className="flex justify-between">
            <PreviousBtn
              path="/omnichannel/whatsApp-integration"
              btnText="WhatsApp Integration"
            />
            <NextBtn
              path="/omnichannel/instagram-integration"
              btnText="Instagram Integration"
            />
          </div>
        </div>

        <RightBar rightBarMenu={rightBarMenu} />
      </div>
    </>
  );
}

export default MessengerIntegration;
