import { IconMenu2, IconSearch } from "@tabler/icons-react";
import React from "react";
import logo from "../images/Logo.png";
import { useDispatch } from "react-redux";
import { toogleMenu } from "../appStore/MenuSlice";
import { Link } from "react-router-dom";

function Navbar() {
  const dispatch = useDispatch();
  return (
    <div className="border-b border-gray-200 sticky top-0 bg-white z-[999]">
      <div className="flex max-w-[1440px] mx-auto h-[76px]">
        <div className="w-[270px]">
          <div className="py-5 px-6 flex items-center gap-2 lg:justify-center sticky top-0 bg-white">
            <img src={logo} alt="logo" />
            <div>
              <p className="text-gray-900 text-xs py-[2px] px-[10px] border border-gray-100 rounded-full">
                Docs
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <div className="py-5 px-8 flex items-center lg:justify-start justify-end h-full">
            <div className="w-full items-center justify-between gap-5 flex-wrap lg:flex hidden">
              <div className="w-[300px] relative">
                <input
                  className="w-full appearance-none rounded-[10px] placeholder:text-gray-400 border border-gray-300 h-10 p-2 pl-8  text-black placeholder-gray-300
        invalid:border-2 invalid:border-state-error focus:outline-none focus:shadow-none focus:ring-0 focus:border-gray-800 "
                  placeholder="Search documentation"
                />
                <IconSearch
                  className="text-gray-500 absolute top-0 bottom-0 h-full left-2"
                  size={20}
                />
              </div>
              <Link
                to="https://app.xunified.com/register"
                className="bg-gray-900 text-white px-[18px] py-2 rounded-[10px] text-sm"
              >
                Get Started For Free
              </Link>
            </div>
            <div className=" lg:hidden flex items-center gap-6">
              <IconSearch className="text-gray-500 " />
              <IconMenu2
                className="text-gray-500"
                onClick={() => dispatch(toogleMenu())}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
